.home {
  height: fit-content;
}

.home__page {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 80%;
  margin: 0 auto;
}

.home__container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: auto;
  flex-basis: 60%;
  height: 90vh;
}

.photo__bg {
  width: 100%;
  height: 50%;
  background-color: #fd8585;
  border-radius: 53% 47% 47% 53% / 71% 66% 34% 29%;
  background-image: url("../../images/image-61.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.home__photo {
  width: 100%;
  height: 100%;
  visibility: hidden;
  border-radius: 60% 40% 47% 53% / 71% 58% 42% 29%;
}

.home__right {
  width: 50%;
  height: 100%;
  border-radius: 60% 40% 47% 53% / 71% 58% 42% 29%;
}

.home__welcome {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.home__buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

.home__icons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.home__proficiency {
  display: flex;
  gap: 20px;
  margin-top: -30px;
}

.home__link,
.home__link:hover,
.home__link:focus,
.home__link:active {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 1100px) {
  .photo__bg {
    height: 75%;
    width: 125%;
  }
}

@media screen and (max-width: 750px) {
  .home__page {
    flex-direction: column-reverse;
    margin: 10px auto;
  }

  .photo__bg {
    width: 75%;
    margin: 0 auto;
  }

  .home__container {
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    align-items: center;
    text-align: center;
  }

  .home__buttons {
    flex-direction: column;
    justify-content: center;
  }

  .home__right {
    height: 30%;
    width: 100%;
    top: 0;
  }

  .home__icons {
    justify-content: center;
    gap: 20px;
  }

  .home__proficiency {
    justify-content: center;
  }
}
