.projects__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 60%;
  margin: 0 auto;
  margin-top: 40px;
  gap: 20px;
}

.projects__card {
  text-decoration: none;
}

@media screen and (max-width: 1300px) {
  .projects__container {
    width: 90%;
    gap: 10px;
    margin: 40px auto;
    justify-items: center;
  }
}

@media screen and (max-width: 1000px) {
  .projects__container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 750px) {
  .projects__container {
    grid-template-columns: 1fr;
    width: 100%;
  }
}
