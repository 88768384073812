.about-me__container {
  width: 75%;
  margin: 20px auto;
  text-align: center;
}

@media screen and (max-width: 1100px) {
  .about-me__container {
    margin: 40px auto;
  }
}
