@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Merriweather", sans-serif;
}

body {
  line-height: 3rem;
  color: white;
  background-color: #2c343f;
}

h1 {
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 300;
}

p {
  font-size: 1.5rem;
  text-wrap: balance;
}

span {
  color: #fd8585;
}

Button:hover {
  color: #fd8585;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 1100px) {
  body {
    line-height: 2.5rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 750px) {
  /* body {
    line-height: 2rem;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  } */
}
