.request-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.request-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 600px;
  height: 600px;
  background-color: #dfdfe9;
  border-radius: 8px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #2c343f !important;
  border-width: 2px !important;
}

.css-1sf7ujp-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #2c343f !important;
  color: white !important;
}

.css-1mnfpbq-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #2c343f !important;
  color: white !important;
}

.css-12s3juk:hover {
  background-color: #2c343f !important;
  color: white !important;
}

.css-x9zh1c:hover {
  background-color: #2c343f !important;
  color: white !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #2c343f !important;
}

.css-1ald77x.Mui-focused {
  color: #2c343f !important;
}

.request-text {
  display: inline-block;
  width: 500px;
  height: 60px;
  font-size: 4rem;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 30px;
}

.contact__footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.contact__details {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

@media screen and (max-width: 750px) {
  .request-app {
    width: 350px;
  }
  .request-text {
    width: 400px;
    font-size: 3rem;
  }
  .request-form {
    width: 100%;
    height: 100%;
    margin-top: 40px;
    padding: 20px;
  }

  .contact__footer {
    flex-direction: column;
  }
}

@media screen and (max-width: 400px) {
  .request-container {
    margin-top: 30px;
  }

  .contact__footer {
    margin-top: 30px;
  }
}
